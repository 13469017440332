@import "../../styles/index.scss";

.header {
  background-color: var(--bg-color);
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.head {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.network {
  height: 100px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & svg {
    width: 24px;
    height: 24px;
    & path {
      fill: var(--text-color);
    }
  }
}

.avatar {
  & .picture {
    width: 225px;
    height: auto;
  }
}

.content {
  margin: 3.5rem 0;

  & .subtitle {
    padding-top: 2rem;
  }
  & .text {
    display: none;
  }
}

.button {
  margin-left: 2em;
}

.avatarDesktop {
  display: none;
}

.infoScroll {
  display: none;
}

@media screen and (min-width: 1000px) {
  .header {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .head {
      & .network {
        height: 150px;
        & svg {
          width: 28px;
          height: 28px;
        }
        & svg:hover path {
          fill: var(--decoration-color);
        }
      }
      & .avatar {
        display: none;
      }
    }

    & .desktopMiddle {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      width: 40%;
      padding: 4em 0;

      & .text {
        display: block;
        margin-top: 1em;
        font-size: 1.1rem;
        line-height: 1.3rem;
        text-align: justify;
        & span {
          font-size: 0.9rem;
          font-style: italic;

          & sup {
            font-variant-position: super;
          }
          & sub {
            font-variant-position: sub;
          }
        }
      }
    }
    & .infoScroll {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 2rem;

      & svg {
        animation-name: bounce;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
      }
      & svg path {
        fill: var(--text-color);
      }

      & .textScroll {
        margin-left: 1rem;
      }
    }
    & .avatarDesktop {
      display: block;
    }
  }
}

/* Fleche de defilement vers le bas bouge */

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}
