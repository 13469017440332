@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

html {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
}

h1 {
  padding-top: 2rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.8rem;
}

h2 {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

@media screen and (min-width: 1000px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}
