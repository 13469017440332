@import "../../styles/index.scss";

.container {
  display: none;
}

@media screen and (min-width: 1000px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 998;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 0.5em 10rem 0.5rem;
    background-color: var(--bg-color);
    box-shadow: 0px -2px 5px var(--shadow-color);

    & .img {
      width: 40px;
    }

    & .links {
      & .link {
        padding: 0 2rem;
      }
      & .link:hover {
        color: var(--decoration-color);
        font-weight: 600;
      }
    }

    & .buttons {
      display: flex;
      justify-content: space-between;
      & span {
        margin-left: 1rem;

        & svg path {
          fill: var(--text-color);
        }
      }
    }
  }
}
