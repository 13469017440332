@import "../../styles/index.scss";

.skills {
  background-color: var(--bg-color);
}

.head {
  margin: 2.5rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 2rem 0;

    & .contentHeader {
      display: flex;
      align-items: center;

      & .subtitle {
        text-align: center;
      }

      & span {
        margin-right: 1rem;
        & svg path {
          fill: var(--text-color);
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .skills {
    margin: 7.5em 0 5em;
    padding-top: 2.5rem;
  }
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    & .content {
      width: 35%;
      flex-wrap: wrap;
    }
  }
}
