@import "../../styles/index.scss";

.labelContainer {
  padding: 0.3em 0.6em;
  margin: 1em 0.2em;
  border-radius: 10px;
  text-align: center;
  font-size: 0.8rem;
  color: #000;
}

.color {
  &React {
    background-color: #9be9ff;
  }

  &Sass {
    background-color: #ffc9e4;
  }

  &Redux {
    background-color: #c8a7fd;
  }
  &PostgreSQL {
    background-color: #7da0bd;
  }
  &Sequelize {
    background-color: #a2c2fc;
  }
  &JavaScript {
    background-color: #fff285;
  }
  &Node {
    background-color: #96eb96;
  }
  &Express {
    background-color: #aaa;
  }
  &NextJs {
    background-color: #aaa;
  }
  &NestJs {
    background-color: #df949f;
  }
}
