@import "../../styles/index.scss";

.head {
  margin-bottom: 2.5rem;
  text-align: center;
}

.containerCarousel {
  border-bottom: 1px solid var(--bar-color);
  padding-bottom: 2em;
}
.containerCarousel:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.containerCarousel {
  margin-bottom: 2em;

  & h2,
  .description {
    line-height: 1.3rem;
    margin: 1em 0.3em 0;
  }

  & .labels {
    margin: 0.5em 0;
    display: flex;
    flex-wrap: wrap;
    line-height: 2.5;
  }

  & .illu {
    margin: 0 0.3em;
    height: 210px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 0px var(--shadow-color-project);
    border: 1px solid var(--bar-color);
  }

  & .buttons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    & .button {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (min-width: 1000px) {
  .fullContainer {
    padding-top: 2.5rem;
  }

  .containerCarousel {
    display: flex;
    flex-direction: row;
    justify-content: normal;
    align-items: center;

    & .illu {
      width: 500px;
      height: 230px;
      border-radius: 10px;
    }
    & .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 5rem;
      padding: 1rem 0;
      flex: 50%;

      & h2 {
        margin: 0;
      }
    }
  }
}
