@import "../../styles/index.scss";

.about {
  background-color: var(--bg-color);
}

.head {
  margin: 0 0 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mainContainer {
  & .illustration {
    display: flex;
    justify-content: center;

    & .picture {
      width: 500px;
      height: auto;
      border-radius: 10px;
    }

    & .codewars {
      display: none;
    }
  }
}

.content {
  margin-top: 1rem;

  & .subtitle {
    text-align: center;
  }
  & .text {
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.2rem;
    text-justify: inter-word;

    text-align: justify;
    hyphens: auto;

    & strong {
      font-style: italic;
    }
  }
  & .stats {
    display: none;
  }
}

.button {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1000px) {
  .about {
    padding-top: 2.5rem;
  }

  .mainContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & .container {
      width: 50%;
    }

    & .illustration {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      min-height: 100%;

      & .picture {
        width: 480px;
        height: 300px;
      }
      & .codewars {
        display: block;
        text-align: center;
        & .text {
          margin-bottom: 0.5em;
          font-style: italic;
        }
        & .number {
          height: 30px;
        }
      }
    }

    & .content {
      & .text {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-top: 1rem;
      }
      & .stats {
        height: 70px;
        margin: 2rem 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;

        & .text {
          margin-top: 1rem;
        }
        & .number {
          font-weight: 500;
        }
      }
    }
    & .button {
      grid-area: button;
    }
  }
}
