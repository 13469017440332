@import "../../styles/index.scss";

.contact {
  background-color: var(--bg-color);
}

.head {
  margin-top: 2.5rem;
  line-height: 1.5rem;
}

.illustration {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  & .picture {
    width: 280px;
    height: auto;
    border-radius: 10px;
  }
}

.contactTitle {
  margin-top: 1.5rem;
}

.content {
  & .text {
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 1.2rem;
    text-justify: inter-word;

    & .mail {
      text-decoration: 1.4px var(--decoration-color) wavy underline;
    }
  }

  & .network {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    width: 200px;
    & svg {
      width: 24px;
      height: 24px;
      & path {
        fill: var(--text-color);
      }
    }
  }
}

.copyright {
  margin: 2.5rem 0;
  padding: 2rem 0;
  font-size: 0.6rem;
  text-align: center;

  & span {
    color: red;
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1000px) {
  .contact {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .division {
    background-color: var(--hover-color);
    width: 100%;
    padding: 3.5rem 5rem;
    align-items: center;
    border-radius: 10px;
    color: var(--bg-color);
    margin: auto;
    display: grid;
    grid-template-columns: 55% 45%;
    grid-template-areas:
      "title illustration"
      "head illustration"
      "content illustration";

    & .head {
      margin-top: 0;
      padding: 0 2rem;
      grid-area: head;
    }
    & .illustration {
      grid-area: illustration;
      height: 238.45px;
      justify-content: flex-end;
    }
    & .title {
      grid-area: title;
    }
    & .content {
      padding: 2rem;
      grid-area: content;
      & .network {
        height: 50px;
        & svg {
          width: 28px;
          height: 28px;
          & path {
            fill: var(--bg-color);
          }
        }
        & svg:hover path {
          fill: var(--hoverContact-color);
        }
      }
      & * {
        fill: var(--bg-color);
      }
    }
  }
  .mail:hover,
  .mail:hover svg path {
    color: var(--hoverContact-color);
    fill: var(--hoverContact-color);
    font-weight: 600;
  }
  .copyright {
    margin: 0;
  }
}
