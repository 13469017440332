.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0.5em 1.5rem;
  background-color: var(--bg-color);
  box-shadow: 0px -2px 5px var(--shadow-color);

  & .img {
    width: 35px;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    & span {
      margin-left: 1rem;

      & svg path {
        fill: var(--text-color);
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .container {
    display: none;
  }
}
